<template>
    <router-view/>
</template>
<script>
export default {
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            // console.log('scroll', event);
            // console.log(window.scrollY);
            // event.target.classList.add('nav-lg-white');
            const navContainEl = document.querySelector('.nav-container');
            if (window.scrollY > 50 && !navContainEl.classList.contains('nav-lg-primary')) {
                navContainEl.classList.add('nav-lg-primary');
            } else if (window.scrollY < 50 && navContainEl.classList.contains('nav-lg-primary')) {
                navContainEl.classList.remove('nav-lg-primary');
            }
        }
    }
};
</script>
<style scoped>
.html{
    scroll-behavior: smooth;
}
</style>
