const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

const cmsUrl = 'cms/';

export default {
    auth: {
        login: baseUrl + 'users/login/',
        logout: baseUrl + 'users/logout/',
        status: baseUrl + 'users/status/',
        register: baseUrl + 'users/register/',
        profileUpdate: baseUrl + 'users/update-profile/',
        userDetails: baseUrl + 'users/user-details/',
        userDelete: baseUrl + 'users/user-delete/',
        updatePassword: baseUrl + 'users/update-password/',
        forgotPassword: baseUrl + 'users/forgot-password/',
        resetPassword: baseUrl + 'users/reset-password/'
    },
    admin: {
        policy: {
            addEdit: baseUrl + 'master-data/policy/add-edit/',
            list: baseUrl + 'master-data/policy/list/'
        },
        news: {
            publish: baseUrl + cmsUrl + 'news/publish/',
            addEdit: baseUrl + cmsUrl + 'news/create/',
            list: baseUrl + cmsUrl + 'news/list/',
            delete: baseUrl + cmsUrl + 'news/delete/',
            details: baseUrl + cmsUrl + 'news/details/',
            imageAddEdit: baseUrl + cmsUrl + 'news-image/create/',
            imageList: baseUrl + cmsUrl + 'news-image/list/',
            imageDelete: baseUrl + cmsUrl + 'news-image/delete/'
        },
        trainingRequest: {
            request: baseUrl + cmsUrl + 'training-request/create/',
            list: baseUrl + cmsUrl + 'training-request/list/',
            delete: baseUrl + cmsUrl + 'training-request/delete/'
        },
        contactUs: {
            addEdit: baseUrl + cmsUrl + 'contact-us/create/',
            delete: baseUrl + cmsUrl + 'contact-us/delete/',
            list: baseUrl + cmsUrl + 'contact-us/list/',
            details: baseUrl + cmsUrl + 'contact-us/detail/'
        },
        contactUsPage: {
            addEdit: baseUrl + cmsUrl + 'contact-us-page/create/',
            delete: baseUrl + cmsUrl + 'contact-us-page/delete/',
            list: baseUrl + cmsUrl + 'contact-us-page/list/',
            details: baseUrl + cmsUrl + 'contact-us-page/detail/'
        },
        banner: {
            addEdit: baseUrl + cmsUrl + 'banner/create/',
            delete: baseUrl + cmsUrl + 'banner/delete/',
            list: baseUrl + cmsUrl + 'banner/list/',
            details: baseUrl + cmsUrl + 'banner/detail/'
        },
        trainingCenter: {
            addEdit: baseUrl + cmsUrl + 'training-center/create/',
            delete: baseUrl + cmsUrl + 'training-center/delete/',
            list: baseUrl + cmsUrl + 'training-center/list/',
            details: baseUrl + cmsUrl + 'training-center/detail/',
            vueSelect: baseUrl + cmsUrl + 'training-center/vue-select/',
            imageAddEdit: baseUrl + cmsUrl + 'training-center/image-create/',
            imageList: baseUrl + cmsUrl + 'training-center/image-list/',
            imageDelete: baseUrl + cmsUrl + 'training-center/image-delete/'
        },
        ourFacility: {
            addEdit: baseUrl + cmsUrl + 'our-facility/create/',
            delete: baseUrl + cmsUrl + 'our-facility/delete/',
            list: baseUrl + cmsUrl + 'our-facility/list/',
            details: baseUrl + cmsUrl + 'our-facility/detail/'
        },
        whyChooseUs: {
            addEdit: baseUrl + cmsUrl + 'why-choose-us/create/',
            delete: baseUrl + cmsUrl + 'why-choose-us/delete/',
            list: baseUrl + cmsUrl + 'why-choose-us/list/',
            details: baseUrl + cmsUrl + 'why-choose-us/detail/'
        },
        aboutUs: {
            addEdit: baseUrl + cmsUrl + 'about-us/create/',
            delete: baseUrl + cmsUrl + 'about-us/delete/',
            list: baseUrl + cmsUrl + 'about-us/list/',
            details: baseUrl + cmsUrl + 'about-us/detail/'
        },
        philosophy: {
            addEdit: baseUrl + cmsUrl + 'our-philosophy/create/',
            delete: baseUrl + cmsUrl + 'our-philosophy/delete/',
            list: baseUrl + cmsUrl + 'our-philosophy/list/',
            details: baseUrl + cmsUrl + 'our-philosophy/detail/'
        },
        socialMedia: {
            addEdit: baseUrl + cmsUrl + 'social-media/create/',
            delete: baseUrl + cmsUrl + 'social-media/delete/',
            list: baseUrl + cmsUrl + 'social-media/list/',
            details: baseUrl + cmsUrl + 'social-media/detail/'
        },
        course: {
            publish: baseUrl + 'training-programs/course/publish/',
            addEdit: baseUrl + 'training-programs/course/create/',
            delete: baseUrl + 'training-programs/course/delete/',
            list: baseUrl + 'training-programs/course/list/',
            details: baseUrl + 'training-programs/course/details/',
            vueSelect: baseUrl + 'training-programs/course/vue-select/',
            detailsUrl: {
                trainingSchedule: {
                    addEdit: baseUrl + cmsUrl + 'training-schedule/create/',
                    delete: baseUrl + cmsUrl + 'training-schedule/delete/',
                    list: baseUrl + cmsUrl + 'training-schedule/list/'
                },
                charge: {
                    addEdit: baseUrl + 'training-programs/course-charge/create/',
                    delete: baseUrl + 'training-programs/course-charge/delete/',
                    list: baseUrl + 'training-programs/course-charge/list/'
                },
                overview: {
                    addEdit: baseUrl + 'training-programs/overview/create/',
                    delete: baseUrl + 'training-programs/overview/delete/',
                    list: baseUrl + 'training-programs/overview/list/',
                    details: baseUrl + 'training-programs/overview/detail/'
                },
                syllabus: {
                    addEdit: baseUrl + 'training-programs/syllabus/create/',
                    delete: baseUrl + 'training-programs/syllabus/delete/',
                    list: baseUrl + 'training-programs/syllabus/list/',
                    details: baseUrl + 'training-programs/syllabus/detail/'
                },
                faculty: {
                    addEdit: baseUrl + 'training-programs/faculty/create/',
                    delete: baseUrl + 'training-programs/faculty/delete/',
                    list: baseUrl + 'training-programs/faculty/list/',
                    details: baseUrl + 'training-programs/faculty/detail/'
                },
                faq: {
                    addEdit: baseUrl + 'training-programs/faq/create/',
                    delete: baseUrl + 'training-programs/faq/delete/',
                    list: baseUrl + 'training-programs/faq/list/',
                    details: baseUrl + 'training-programs/faq/detail/'
                }

            }
        },
        payment: {
            courseRegisterList: baseUrl + 'training-programs/course-register/list/',
            courseRegisterDetails: baseUrl + 'training-programs/course-register/details/',
            updatingPaymentStatus: baseUrl + 'training-programs/course-register/update-status/',
            checkStatus: baseUrl + 'payment/transaction-status/',
            transactionList: baseUrl + 'payment/transaction/list/',
            transactionDetails: baseUrl + 'payment/transaction/details/'
        }
    },

    masterData: {
        NewsCategory: {
            addEdit: baseUrl + 'master-data/news-category/create/',
            list: baseUrl + 'master-data/news-category/list/',
            newsCategoryOptions: baseUrl + 'master-data/news-category/vue-select/',
            delete: baseUrl + 'master-data/news-category/delete/'
        },
        State: {
            addEdit: baseUrl + 'master-data/state/create/',
            list: baseUrl + 'master-data/state/list/',
            delete: baseUrl + 'master-data/state/delete/',
            stateOptions: baseUrl + 'master-data/state/vue-select/'
        },
        District: {
            addEdit: baseUrl + 'master-data/district/create/',
            list: baseUrl + 'master-data/district/list/',
            delete: baseUrl + 'master-data/district/delete/',
            stateOptions: baseUrl + 'master-data/state/vue-select/',
            districtOptions: baseUrl + 'master-data/district/vue-select/'
        }
    },

    website: {
        footer: baseUrl + cmsUrl + 'website/footer/',
        home: baseUrl + cmsUrl + 'website/home/',
        news: baseUrl + cmsUrl + 'website/news/',
        newsDetails: baseUrl + cmsUrl + 'website/news-detail/',
        aboutUs: baseUrl + cmsUrl + 'website/about-us/',
        contactUs: baseUrl + cmsUrl + 'website/contact-us/',
        trainingCenter: baseUrl + cmsUrl + 'website/training-centers/',
        course: {
            coursesOffered: baseUrl + cmsUrl + 'website/offered-course/',
            coursesOfferedDetails: baseUrl + 'training-programs/course/all-details/',
            coursesApply: baseUrl + 'training-programs/course/apply/',
            coursesApplyDetails: baseUrl + 'training-programs/course/applied-detail/',
            coursesOrganizationUserCreate: baseUrl + 'training-programs/course/organization-user/create/',
            coursesOrganizationUserDelete: baseUrl + 'training-programs/course/organization-user/delete/',
            coursesOrganizationUserDetails: baseUrl + 'training-programs/organization-user/detail/',
            myRegisteredCourses: baseUrl + 'training-programs/course/my-course-registered/',
            myAttendedCourses: baseUrl + 'training-programs/course/my-course-attended/',
            payment: {
                initiatePayment: baseUrl + 'payment/initiate-transaction/'
            }
        }
    }
};
