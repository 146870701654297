const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');

export default {
    path: '/',
    name: 'AuthLayout',
    redirect: '/login/',
    component: Layout,
    children: [
        {
            path: '/login/',
            name: 'LoginPage',
            component: () => import('../views/auth/Login')
        },
        {
            path: '/set-new-password/:username/',
            name: 'SetNewPassword',
            component: () => import('../views/auth/SetNewPassword')
        },
        {
            path: '/login/:id',
            name: 'ApplyLoginPage',
            component: () => import('../views/auth/Login')
        },
        {
            path: '/admin/login/',
            name: 'AdminLoginPage',
            component: () => import('../views/admin/auth/Login')
        },
        {
            path: '/admin/logout/',
            name: 'AdminLoginPage',
            component: () => import('../views/admin/auth/Logout')
        },
        {
            path: '/update-password/',
            name: 'UpdatePassword',
            component: () => import('../views/admin/auth/UpdatePassword')
        },
        {
            path: '/register/',
            name: 'Register',
            component: () => import('../views/Registration')
        },
        {
            path: '/register-individual/',
            name: 'RegisterIndividual',
            component: () => import('../views/RegisterIndividual')
        },
        {
            path: '/register-organization/',
            name: 'RegisterOrganization',
            component: () => import('../views/RegisterOrganization')
        },
        {
            path: '/payment-not-logged-in/',
            name: 'PaymentNotLoggedIN',
            component: () => import('../views/payment/PaymentNotLoggedIn')
        },
        {
            path: '/paymentsuccess-page/',
            name: 'Payment-succes-screen',
            component: () => import('../views/payment/Payment-succes-screen')
        }
    ]
};
