<template>
    <div class="mb-4" :class="{'text-center':align==='center','text-left-resp':align==='left'}">
        <h3 class="mb-3 fs-lg--1 font-lato-semi-bold"
            :class="{'bottom-tag-center':bottomTagAlign === 'center', 'bottom-tag-left':bottomTagAlign === 'left', 'text-black': color === 'black', 'text-white-1': color === 'white'}">
            {{ title }}</h3>
        <p class="font-lato-medium text-3 text-center px-lg-9 lh-2">{{ info }}</p>
    </div>
</template>
<script> export default {
    name: 'SectionHeading',
    props: {
        title: {
            type: String
        },
        info: {
            type: String
        },
        color: {
            type: String,
            default: 'black'
        },
        align: {
            type: String,
            default: 'center'
        },
        bottomTagAlign:
            {
                type: String,
                default: 'center'
            }
    }
};
</script>
<style scoped lang="scss">
.bottom-tag-center {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        //width: 5%;
        width: 3.5rem;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        bottom: -1rem;
        //background-color: rgba(0,0,0,0.65);
        background-color: var(--color-primary);
        //margin-top: 1rem;
    }
}

.bottom-tag-left {
    position: relative;
    display: inline;

    &::after {
        content: '';
        position: absolute;
        //width: 5%;
        width: 3.5rem;
        //transform: translateX(-50%);
        bottom: -1rem;
        left: 0;
        height: 2px;
        //background-color: rgba(0,0,0,0.65);
        background-color: var(--color-primary);
        //margin-top: 1rem;
    }
}
</style>
