import guards from './guards';

const AdminLayout = () => import(/* webpackChunkName: "layout" */'../layouts/AdminLayout');

export default {
    path: '/admin/',
    name: 'AdminDashboardLayout',
    redirect: '/admin/',
    component: AdminLayout,
    beforeEnter: guards.adminLoggedInGuard,
    children: [
        {
            path: '/admin/',
            name: 'AdminHome',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/AdminHome')
        },
        {
            path: '/admin/banner/',
            name: 'Banner',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/master-data/banner/BannerListing')
        },
        {
            path: '/admin/banner/:id/details/',
            name: 'BannerDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/master-data/banner/DetailsPage')
        },
        {
            path: '/admin/news/',
            name: 'News',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/news/NewsListing')
        },
        {
            path: '/admin/news/:id/details/',
            name: 'NewsDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/news/DetailsPage')
        },
        {
            path: '/admin/news/',
            name: 'News',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/news/NewsListing')
        },
        {
            path: '/admin/news/:id/details/',
            name: 'NewsDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/news/DetailsPage')
        },
        {
            path: '/admin/training-centers/',
            name: 'TrainingCenters',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/home/training-centers/TrainingCenterListing')
        },
        {
            path: '/admin/training-centers/:id/details/',
            name: 'TrainingCentersDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/home/training-centers/DetailsPage')
        },
        {
            path: '/admin/our-facility/',
            name: 'OurFacility',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/home/our-facility/OurFacilityListing')
        },
        {
            path: '/admin/our-facility/:id/details/',
            name: 'OurFacilityDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/home/our-facility/DetailsPage')
        },
        {
            path: '/admin/why-choose-us/',
            name: 'OurFacility',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/home/why-choose-us/WhyChooseUsListing')
        },
        {
            path: '/admin/why-choose-us/:id/details/',
            name: 'OurFacilityDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/home/why-choose-us/DetailsPage')
        },
        {
            path: '/admin/training-requests/',
            name: 'TrainingRequests',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/home/request-training/RequestTrainingList')
        },
        {
            path: '/admin/master-data/',
            name: 'MasterData',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/master-data/MasterData')
        },
        {
            path: '/admin/about-us/',
            name: 'AboutUs',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/about-us/about-us/AboutUsListing')
        },
        {
            path: '/admin/about-us/:id/details/',
            name: 'AboutUsDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/about-us/about-us/DetailsPage')
        },
        {
            path: '/admin/philosophy/',
            name: 'Philosophy',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/about-us/philosophy/PhilosophyListing')
        },
        {
            path: '/admin/philosophy/:id/details/',
            name: 'PhilosophyDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/about-us/philosophy/DetailsPage')
        },
        {
            path: '/admin/contact-us-page/',
            name: 'ContactUs',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/contactUs/contact-us-page/ContactUsItemListing')
        },
        {
            path: '/admin/contact-us-page/:id/details/',
            name: 'ContactUsDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/contactUs/contact-us-page/DetailsPage')
        },
        {
            path: '/admin/contact-us/',
            name: 'ContactUs',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/contactUs/ContactUsRequestListing')
        },
        {
            path: '/admin/courses/',
            name: 'ContactUs',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/our-courses/OurCoursesListing')
        },
        {
            path: '/admin/training-schedule/',
            name: 'ContactUs',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/our-courses/training-schedule/TrainingScheduleListing')
        },
        {
            path: '/admin/course/:id/details/',
            name: 'ContactUsDetails',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/our-courses/DetailsPage')
        },
        {
            path: '/admin/social-media/',
            name: 'ContactUs',
            component: () => import(/* webpackChunkName: "home" */'../views/admin/master-data/SocialMedia')
        },
        {
            path: '/admin/terms-and-privacy/',
            name: 'TermsAndPrivacy',
            props: true,
            component: () => import('../views/admin/master-data/TermsAndPrivacy')
        },
        {
            path: '/admin/course-register/',
            name: 'CourseRegister',
            props: true,
            component: () => import('../views/admin/payments/CourseRegisters')
        },
        {
            path: '/admin/course-register/:id/details/',
            name: 'CourseRegister',
            props: true,
            component: () => import('../views/admin/payments/CourseRegisterDetailsPage')
        },
        {
            path: '/admin/transactions/',
            name: 'Transactions',
            props: true,
            component: () => import('../views/admin/payments/Transactions')
        }
    ]
};
