import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
// const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
// const About = () => import(/* webpackChunkName: "home" */'../views/About');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.statusGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: () => import(/* webpackChunkName: "home" */'../views/Home')
        },
        {
            path: '/about-us/',
            name: 'About Us',
            component: () => import(/* webpackChunkName: "home" */'../views/About')
        },
        {
            path: '/course-offered/',
            name: 'Course Offered',
            component: () => import(/* webpackChunkName: "home" */'../views/CourseOffered')
        },
        {
            path: '/course-offered/:id/details/',
            name: 'CourseInformation',
            component: () => import(/* webpackChunkName: "home" */'../views/training-programs/CourseInformation')
        },
        {
            path: '/course-details/:id/individual/',
            name: 'CoursePayment',
            component: () => import(/* webpackChunkName: "home" */'../views/payment/CourseDetailsIndividual')
        },
        {
            path: '/course-details/:id/organization/',
            name: 'CourseApply',
            component: () => import(/* webpackChunkName: "home" */'../views/payment/organization/CourseDetailsOrganization')
        },
        {
            path: '/my-courses/',
            name: 'MyCoursesList',
            component: () => import(/* webpackChunkName: "home" */'../views/my-courses/individual/MyCoursesListPage')
        },
        {
            path: '/organization-courses/',
            name: 'OrganizationCoursesList',
            component: () => import(/* webpackChunkName: "home" */'../views/my-courses/organization/OrganizationCoursesListPage')
        },
        {
            path: '/training-centers/',
            name: 'TrainingCenters',
            component: () => import(/* webpackChunkName: "home" */'../views/TrainingCentersPage')
        },
        {
            path: '/payment-success/',
            name: 'Payment-Success',
            component: () => import(/* webpackChunkName: "home" */'../views/payment/Payment-Success')
        },
        {
            path: '/training-programs/',
            name: 'TrainingPrograms',
            component: () => import(/* webpackChunkName: "home" */'../views/MyTrainingPrograms')
        },
        {
            path: '/contact/',
            name: 'Contact',
            component: () => import(/* webpackChunkName: "home" */'../views/ContactUs')
        },
        {
            path: '/news/',
            name: 'AllNewsPage',
            component: () => import('../views/AllNewsPage')
        },
        {
            path: '/news-category/:category',
            name: 'AllNewsPage',
            component: () => import('../views/AllNewsPage')
        },
        {
            path: '/news/:id',
            name: 'NewsArticlePage',
            props: true,
            component: () => import('../views/news/NewsArticlePage')
        },
        {
            path: '/profile-update/',
            name: 'ProfileUpdate',
            component: () => import(/* webpackChunkName: "home" */'../views/profile-update/ProfileUpdate')
        }
    ]
};
