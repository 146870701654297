import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';
import authRoutes from './auth-layout';
import adminRoutes from './admin-layout';

Vue.use(VueRouter);

const routes = [
    appRoutes,
    adminRoutes,
    authRoutes
];

if (process.env.NODE_ENV !== 'production') {
    const docsRoutes = require('lego-framework/src/router/docs-layout');
    routes.push(docsRoutes.default);
}
const scrollBehavior = async function (to, from, savedPosition) {
    if (to.hash) {
        setTimeout(function () {
            return window.scrollTo({
                top: document.querySelector(to.hash).offsetTop,
                behavior: 'smooth'
            });
        }, 1000);
    } else {
        return { x: 0, y: 0 };
    }
};
// if (savedPosition) {
//     console.log('saved position', savedPosition);
//     return {
//         savedPosition,
//         behavior: 'smooth'
//     };
// } else {
//     return {
//         // top: 0
//         x: 0,
//         y: 0,
//         behavior: 'smooth'
//     };
// }

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior

});

router.beforeResolve((to, from, next) => {
    if (to.name) {
    }
    next();
});

router.afterEach(() => {
});

export default router;
